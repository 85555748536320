const no_legend = false
const l_rec = [
    '<b>Not applicable</b>: This query is not relevant, makes not sense, or the rating is unknown.',
    '<b>Not recommended</b>: The product is NOT suitable for this particular requirement.',
    '<b>Rarely recommended</b>: On a rating scale of 1 - 4, this is a 1.',
    '<b>Recommended</b>: On a rating scale of 1 - 4, this is a 2.',
    '<b>Highly recommended</b>: On a rating scale of 1 - 4, this is a 3.',
    '<b>Very strongly recommended</b>: On a rating scale of 1 - 4, this is a 4.'
]
const l_cont = ['<b>No</b>: Substance is not  part of the product.', '<b>Yes</b>: Substance is part of the product.']
const l_avai = [
    '<b>No</b>: According to the official country inventories, the product is NOT available in this country/region.',
    '<b>Yes</b>: According to the official country inventories, the product is available in this country/region.'
]
const l_imp = [
    '<b>Not applicable</b>: This query is not relevant, does not make sense, or the rating is unknown.',
    '<b>-3 - -1</b>: Strongly negative impact - low negative impact',
    '<b>0</b>: No impact',
    '<b>1 - 10</b>: very low positive impact - excellent positive impact'
]
const l_sol = [
    '<b>Not applicable</b>: This query is not relevant, does not make sense, or the rating is unknown.',
    '<b>0</b>: The product is not soluble in solvent or separates again after a short time.',
    '<b>1</b>: Naming tbd',
    '<b>2</b>: Solution is turbid but stable even after 24 h and not separated.',
    '<b>3</b>: Naming tbd',
    '<b>4</b>: The product is clearly soluble in solvent.'
]

export default {
    applications: {
        legend: l_rec
    },
    availableIns: {
        legend: l_avai
    },
    binderCompatibilities: {
        legend: l_rec
    },
    coatingLayers: {
        legend: l_rec
    },
    coatingThicknesses: {
        legend: l_rec
    },
    competitionProducts: {
        legend: no_legend
    },
    countryRegulations: {
        legend: no_legend
    },
    cupsets: {
        legend: no_legend
    },
    curingMechanisms: {
        legend: l_rec
    },
    ecoLabels: {
        legend: no_legend
    },
    effectFunctionalities: {
        legend: l_imp
    },
    foodContacts: {
        legend: no_legend
    },
    toySafeties: {
        legend: no_legend
    },
    productGroupOverviews: {
        legend: no_legend
    },
    formulations: {
        legend: l_rec
    },
    leadTimes: {
        legend: no_legend
    },
    marketings: {
        legend: no_legend
    },
    pigments: {
        legend: l_rec
    },
    pigmentConcentratesCriteria: {
        legend: l_rec,
        onlyForSegments: ['4']
    },
    pigmentFillerTypes: {
        legend: l_rec
    },
    priceSensitivities: {
        legend: no_legend
    },
    productAlternatives: {
        legend: no_legend
    },
    solubilities: {
        legend: l_sol
    },
    specialIngredients: {
        legend: l_cont
    },
    substrates: {
        legend: l_rec
    },
    technologies: {
        legend: l_rec
    }
}
