<template>
    <v-navigation-drawer v-model="isVisible" app temporary>
        <v-list v-if="isLoggedIn && currentUser" dense>
            <v-list-item two-line>
                <v-list-item-avatar v-if="currentUser.avatar">
                    <img :src="currentUser.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>

        <v-list dense>
            <template v-for="item in navItems">
                <v-list-item v-if="item.condition" :key="item.title" :to="item.to" exact>
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-t="item.navText" />
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item v-if="!isLoggedIn">
                <v-btn
                    v-t="'navigation.login'"
                    class="white--text"
                    color="primary"
                    block
                    tile
                    @click="$router.gotoLogin()"
                />
            </v-list-item>
        </v-list>

        <template v-slot:append>
            <div v-if="isLoggedIn" class="pa-2">
                <v-btn v-t="'navigation.logout'" color="secondary" class="black--text" block tile @click="logout" />
            </div>
            <div v-if="versionInfo" class="text-right caption mr-3">
                {{ versionInfo }}
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
    mdiAccountEdit,
    mdiAccountMultiple,
    mdiAccountPlus,
    mdiAlertBox,
    mdiArchive,
    mdiCubeOutline,
    mdiEarthBox,
    mdiHome,
    mdiHomeAlert,
    mdiLogin,
    mdiPlusBox,
    mdiSquareEditOutline,
    mdiTableEdit,
    mdiTableLarge
} from '@mdi/js'

export default {
    name: 'Navigation',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        versionInfo: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        svgAddProduct: mdiPlusBox,
        svgAddUser: mdiAccountPlus,
        svgAllOpenProducts: mdiHomeAlert,
        svgArchive: mdiArchive,
        svgCountryInventory: mdiEarthBox,
        svgCube: mdiCubeOutline,
        svgEditProduct: mdiSquareEditOutline,
        svgEditUser: mdiAccountEdit,
        svgHome: mdiHome,
        svgLogin: mdiLogin,
        svgMyTasks: mdiAlertBox,
        svgResponsible: mdiAccountMultiple,
        svgTableEdit: mdiTableEdit,
        svgTable: mdiTableLarge,
        isVisible: false
    }),
    computed: {
        ...mapGetters('auth', ['allowedActions', 'isLoggedIn']),
        ...mapState('auth', ['currentUser']),
        navItems() {
            return [
                {
                    condition: true,
                    to: { name: 'home' },
                    icon: this.svgHome,
                    navText: 'navigation.home'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.CREATE_PRODUCT,
                    to: { name: 'createProduct' },
                    icon: this.svgAddProduct,
                    navText: 'navigation.createProduct'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.EDIT_PRODUCT,
                    to: { name: 'myProducts' },
                    icon: this.svgEditProduct,
                    navText: 'navigation.editProduct'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.ACCESS_MY_OPEN_TASKS,
                    to: { name: 'myOpenProducts' },
                    icon: this.svgMyTasks,
                    navText: 'navigation.myOpenTasks'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.SEARCH_PRODUCT,
                    to: { name: 'products' },
                    icon: this.svgTable,
                    navText: 'navigation.productGroups'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.VIEW_RESPONSIBILITIES,
                    to: { name: 'contacts' },
                    icon: this.svgResponsible,
                    navText: 'navigation.responsibilities'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.ACCESS_ARCHIVE,
                    to: { name: 'archive' },
                    icon: this.svgArchive,
                    navText: 'navigation.archive'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.CREATE_USER,
                    to: { name: 'createUser' },
                    icon: this.svgAddUser,
                    navText: 'navigation.createUser'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.EDIT_USER,
                    to: { name: 'users' },
                    icon: this.svgEditUser,
                    navText: 'navigation.editUser'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.VIEW_ALL_PENDING_TASKS,
                    to: { name: 'allOpenProducts' },
                    icon: this.svgAllOpenProducts,
                    navText: 'navigation.allOpenProducts'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.SEARCH_PRODUCT,
                    to: { name: 'multipleProductsEditor' },
                    icon: this.svgTableEdit,
                    navText: 'navigation.multipleProductsEditor'
                },
                {
                    condition: this.isLoggedIn && this.allowedActions.EDIT_REGULATORY_INFORMATION,
                    to: { name: 'countryInventory' },
                    icon: this.svgCountryInventory,
                    navText: 'navigation.countryInventory'
                }
            ]
        }
    },
    watch: {
        value: {
            handler: function () {
                this.isVisible = !!this.value
            },
            immediate: true
        },
        isVisible: function () {
            this.$emit('input', this.isVisible)
        }
    },
    methods: {
        logout() {
            window.location.href = '/saml/logout'
        }
    }
}
</script>
