<template>
    <div class="my-8">
        <v-badge v-if="badge || badge === 0" color="orange" overlap>
            <v-btn :disabled="disabled" :to="to" tile x-large color="primary" min-height="100" min-width="250">
                <v-icon v-if="icon" left>{{ icon }}</v-icon>
                <slot />
            </v-btn>
            <template v-slot:badge>{{ badge }}</template>
        </v-badge>
        <v-btn v-else :disabled="disabled" :to="to" tile x-large color="primary" min-height="100" min-width="250">
            <v-icon v-if="icon" left>{{ icon }}</v-icon>
            <slot />
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'DashboardButton',
    props: {
        to: {
            type: [Object, String],
            default: ''
        },
        badge: {
            type: [String, Number],
            default: null
        },
        icon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
