import 'url-search-params-polyfill'
import 'whatwg-fetch'

import Vue, { CreateElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/vue-noty'
import { apolloProvider } from './graphQl/vue-apollo'
import TextareaAutosize from 'vue-textarea-autosize'

Vue.config.productionTip = false
Vue.use(TextareaAutosize)

store
    .dispatch('auth/fetchCurrentUser')
    .catch(() => {
        // Swallow error: If this call fails it simply means
        // that the user is currently not signed in (SSO)
    })
    .then(() => {
        new Vue({
            router,
            store,
            vuetify,
            i18n,
            apolloProvider,
            render: (h: CreateElement) => h(App)
        }).$mount('#app')
    })
