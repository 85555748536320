import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'

import { apolloClient } from '@/graphQl/vue-apollo'
import { buildMutationFromProducts, buildQueryFromCriteria } from '@/graphQl/queryBuilder'
import {
    determineEditRight,
    determineMarketingEditScope,
    determineSegmentEditScope,
    flattenProduct,
    moveArrayElement
} from '@/components/multiple-products-editor/store/utils'

import scoreUtils from '@/components/multiple-products-editor/score/scoreCalculationUtils'

const mpeState = (): MpeState => ({
    isLoading: false,
    isRendering: false,

    selectedCriteria: [],
    selectedSegment: null,
    selectedTechnology: null,
    selectedSegmentCache: null,
    selectedTechnologyCache: null,

    products: [],
    originProducts: {},

    appliedFilters: {},
    deselectedProducts: [],
    appliedSortOptions: {
        sortBy: 'name',
        sortDesc: true
    }
})

const mpeGetters: GetterTree<MpeState, RootState> = {
    isFiltered({ appliedFilters }) {
        return Object.keys(appliedFilters).length > 0
    },
    regulatoryCriteria({ selectedCriteria }) {
        return selectedCriteria.filter(c => c.categoryId === 'regulatory-information')
    },
    relevantScoreCriteria({ selectedCriteria }) {
        return selectedCriteria.filter(
            c =>
                (c.categoryId === 'segment-information' && c.tableId !== 'dosageLevels') ||
                (c.categoryId === 'global-technical-information' &&
                    c.tableId !== 'products' &&
                    c.tableId !== 'technologies')
        )
    },
    scoreCriteria({ selectedCriteria }) {
        return selectedCriteria.filter(c => c.type === 'score')
    },
    getFilteredProducts: ({ appliedSortOptions, products, appliedFilters }, getters) => {
        const filters: Record<string, Function> = {
            text: (el: string, values: string[]) => {
                if (!el) {
                    return false
                }
                const total = values.map(val => el.toString().toLowerCase().includes(val.toLowerCase()))
                return total.includes(true)
            },
            options: (el: string, values: string) => values.includes(el)
        }

        let result = [...products]

        Object.keys(appliedFilters).forEach(key => {
            const filter = appliedFilters[key]
            if (filter.values.length > 0) {
                result = result.filter(product => filters[filter.type](product[key], filter.values))
            }
        })

        // return result if sorted criteria is not selected
        const { currentSortedCriteria } = getters
        if (!currentSortedCriteria) {
            return result
        }
        const { sortBy, sortDesc } = appliedSortOptions
        const { type, enum: enumValues } = currentSortedCriteria as TableCriteriaItem
        const sortedResults = result.sort((a, b) => {
            const aKey = a[sortBy]
            const bKey = b[sortBy]

            if (aKey != null && bKey != null) {
                // sort by string value
                if (type === 'text') {
                    return bKey.toString().localeCompare(aKey.toString())
                }
                // sort by number/score
                if (type === 'number' || type === 'score') {
                    if (aKey === 'UNSET' && bKey === 'UNSET') {
                        return 0
                    }
                    if (aKey === 'UNSET' || bKey === 'UNSET') {
                        return aKey !== 'UNSET' ? 1 : -1
                    }
                    return aKey - bKey
                }
                // sort by enum values
                if (type === 'select' && enumValues) {
                    const aIndex = enumValues.findIndex(e => e.value === aKey)
                    const bIndex = enumValues.findIndex(e => e.value === bKey)
                    if (aIndex === -1 || bIndex === -1) {
                        return bKey.toString().localeCompare(aKey.toString())
                    }
                    return bIndex - aIndex
                }
            }
            return 0
        })

        if (sortDesc) {
            return sortedResults.reverse()
        } else {
            return sortedResults
        }
    },
    currentSortedCriteria({ selectedCriteria, appliedSortOptions }) {
        return selectedCriteria.find(crit => crit.id === appliedSortOptions.sortBy)
    }
}

const mutations: MutationTree<MpeState> = {
    setLoading(state, to: boolean) {
        state.isLoading = to
    },
    setRendering(state, to: boolean) {
        state.isRendering = to
    },
    setDeselectedProduct(state, payload: { id: number; value: boolean }) {
        const { id, value } = payload
        const productIndex = state.products.findIndex(p => p.id === id)
        state.products[productIndex].isSelected = value

        if (!value) {
            state.deselectedProducts.push(id)
        } else {
            const index = state.deselectedProducts.indexOf(id)
            if (index !== -1) {
                state.deselectedProducts.splice(index, 1)
            }
        }
    },
    setProduct(state, payload: { id: number; product: Product }) {
        const { id, product } = payload
        const index = state.products.findIndex(p => p.id === id)
        Vue.set(state.products, index, product)
    },
    setProducts(state, products: Product[]) {
        state.products = [...products]
    },
    setProductValue(state, payload: { id: number; path: string; value: string | number | boolean }) {
        const { id, path, value } = payload
        const index = state.products.findIndex(p => p.id === id)
        state.products[index][path] = value
    },
    setOriginProductValue(state, payload: { id: number; path: string; value: string | number | boolean }) {
        const { id, path, value } = payload
        state.originProducts[id][path] = value
    },
    setSelectedCriteria(state, criteria: TableCriteriaItem[]) {
        localStorage.setItem('mpe.selectedCriteria', JSON.stringify(criteria))
        state.selectedCriteria = criteria
    },
    setSelectedSegment(state, segment: SchemaItem) {
        state.selectedSegmentCache = state.selectedSegment
        localStorage.setItem('mpe.selectedSegment', segment.value.toString(10))
        state.selectedSegment = segment
    },
    setSelectedTechnology(state, technology: SchemaItem) {
        state.selectedTechnologyCache = state.selectedTechnology
        localStorage.setItem('mpe.selectedTechnology', technology.value.toString(10))
        state.selectedTechnology = technology
    },
    setFilter(state, payload: { key: string; type: string; values: string[] }) {
        const { key, type, values } = payload
        if (values.length > 0) {
            Vue.set(state.appliedFilters, key, { type, values })
        } else {
            Vue.delete(state.appliedFilters, key)
        }
    },
    setSortOptions(state, payload: SortOptions) {
        Vue.set(state, 'appliedSortOptions', payload)
    },
    setOriginProducts(state, products: KeyMap<Product>) {
        state.originProducts = products
    },
    setDeselectedProducts(state, value: number[]) {
        state.deselectedProducts = value
    }
}

const actions: ActionTree<MpeState, RootState> = {
    async initMpe({ dispatch }, preSelectedCriteria: TableCriteriaItem[]) {
        const segmentStorageString = localStorage.getItem('mpe.selectedSegment')
        if (segmentStorageString) {
            try {
                const segmentStorageValue = parseInt(segmentStorageString, 10)
                await dispatch('applySegment', segmentStorageValue)
            } catch {
                await dispatch('applySegment', 1)
            }
        } else {
            await dispatch('applySegment', 1)
        }

        const technologyStorageString = localStorage.getItem('mpe.selectedTechnology')
        if (technologyStorageString) {
            try {
                const technologyStorageValue = parseInt(technologyStorageString, 10)
                await dispatch('applyTechnology', technologyStorageValue)
            } catch {
                await dispatch('applyTechnology', 4)
            }
        } else {
            await dispatch('applyTechnology', 4)
        }

        const selectedCriteriaStorageString = localStorage.getItem('mpe.selectedCriteria')
        if (selectedCriteriaStorageString) {
            try {
                const selectedCriteriaStorageValue = JSON.parse(selectedCriteriaStorageString)
                await dispatch('applySelectedCriteria', selectedCriteriaStorageValue)
            } catch {
                await dispatch('applySelectedCriteria', preSelectedCriteria)
            }
        } else {
            await dispatch('applySelectedCriteria', preSelectedCriteria)
        }
    },
    removeSelectedCriteriaWithId({ state, commit, dispatch }, criteriaId: string) {
        const { products, originProducts } = state

        const cleanedOriginProducts: KeyMap<Product> = {}
        const cleanedProductList = products.map(product => {
            const newOriginProduct = Object.assign({}, originProducts[product.id])
            const newProduct = Object.assign({}, product)

            if (!['id', 'productGroupWebsite'].includes(criteriaId)) {
                delete newOriginProduct[criteriaId]
                delete newProduct[criteriaId]
            }

            cleanedOriginProducts[product.id] = newOriginProduct
            return newProduct
        })
        commit('setOriginProducts', cleanedOriginProducts)
        commit('setProducts', cleanedProductList)

        const newSelectedCriteria = state.selectedCriteria.filter(
            (criteria: TableCriteriaItem) => criteria.id !== criteriaId
        )
        commit('setSelectedCriteria', newSelectedCriteria)

        dispatch('updateOriginScore')
        dispatch('updateScore')
    },
    applySegment({ commit, rootState }, selectedSegmentId: number) {
        const segment = rootState.schema.marketSegments.find(
            (s: TextValueItem<number>) => s.value === selectedSegmentId
        )
        commit('setSelectedSegment', segment)
    },
    applyTechnology({ commit, rootState }, selectedTechnologyId: number) {
        const technology = rootState.schema.technologies.find(
            (t: TextValueItem<number>) => t.value === selectedTechnologyId
        )
        commit('setSelectedTechnology', technology)
    },
    applyFilter({ commit }, payload: { key: string; filter: TableFilter }) {
        commit('setFilter', payload)
    },
    applySortOptions({ state, commit }, columnPath: string) {
        const { sortBy, sortDesc } = state.appliedSortOptions
        const so: SortOptions = {
            sortBy: columnPath,
            sortDesc: !sortDesc
        }

        if (columnPath !== sortBy) {
            so.sortDesc = sortDesc
        }
        commit('setSortOptions', so)
    },
    async applySelectedCriteria({ state, rootState, rootGetters, commit, dispatch }, criteria: TableCriteriaItem[]) {
        const { selectedSegment } = state
        const { marketSegments, tableItemsFlat } = rootState.schema
        const allowedActions = rootGetters['auth/allowedActions']
        const restrictedMarketSegments = rootGetters['auth/restrictedMarketSegments']
        const restrictedRootMarketSegments = rootGetters['auth/restrictedRootMarketSegments']
        const isAdministrator: boolean = rootGetters['auth/isAdministrator']

        const tempIds = criteria.map(e => e.id)
        const stateCriteria = tempIds.map(e => tableItemsFlat.find(i => i.id === e) as TableCriteriaItem)

        const newCriteria = stateCriteria.map(c => {
            return {
                ...c,
                isEditable: determineEditRight(c.criteriaName, c.tableId, c.editScope, isAdministrator, allowedActions),
                isInEditScope:
                    c.categoryId === 'marketing-information' && c.marketingId
                        ? determineMarketingEditScope(
                              c.marketingId,
                              marketSegments,
                              isAdministrator,
                              restrictedRootMarketSegments
                          )
                        : c.categoryId === 'segment-information'
                        ? determineSegmentEditScope(selectedSegment, isAdministrator, restrictedMarketSegments)
                        : true
            }
        })

        commit('setSelectedCriteria', newCriteria)
        await dispatch('loadProducts')
    },
    reorderCriteria({ state, commit }, payload: { from: number; to: number }) {
        const { from, to } = payload
        const criteria = [...state.selectedCriteria]
        moveArrayElement(criteria, from, to)
        commit('setSelectedCriteria', criteria)
    },
    async loadProducts({ state, getters, commit, dispatch }) {
        commit('setLoading', true)
        const {
            selectedSegment,
            selectedTechnology,
            selectedCriteria,
            deselectedProducts,
            products,
            selectedTechnologyCache,
            selectedSegmentCache
        } = state

        const oldProducts = products.map(p => Object.assign({}, p))

        const options: GraphQlQueryOptions = {}
        if (selectedSegment && selectedTechnology) {
            options.segmentAndTechnologyFilter = {
                segment: selectedSegment.value,
                technology: selectedTechnology.value
            }
        }
        const marketingIds = selectedCriteria
            .filter(criterion => criterion.tableId === 'marketings')
            .map(criterion => (criterion.marketingId ? criterion.marketingId : 0))

        if (selectedSegment) {
            marketingIds.push(selectedSegment.value)
        }
        if (marketingIds.length > 0) {
            options.marketSegmentFilter = {
                ids: marketingIds
            }
        }
        const graphQlQuery = buildQueryFromCriteria(selectedCriteria, options)
        const response = await apolloClient.query({ query: graphQlQuery })
        const data: Product[] = await response.data.allProducts
            .filter((p: Product) => p.segmentation !== 'CANCEL')
            .map((p: Product) => Object.assign(p, { isSelected: !deselectedProducts.includes(p.id) }))

        const newOriginProducts: KeyMap<Product> = {}
        const newSegTecFlag = selectedTechnology !== selectedTechnologyCache || selectedSegment !== selectedSegmentCache

        const flatProducts = data.map(p => {
            const loadedProduct = flattenProduct(p, selectedCriteria)
            // apply edited changes
            const editedProduct = Object.assign({}, loadedProduct)
            const oldProduct = oldProducts.find(oldP => oldP.id === loadedProduct.id)
            if (oldProduct) {
                selectedCriteria.forEach(c => {
                    if (
                        c.categoryId !== 'segment-information' ||
                        (c.categoryId === 'segment-information' && !newSegTecFlag)
                    ) {
                        if (oldProduct[c.id] !== undefined) {
                            editedProduct[c.id] = oldProduct[c.id]
                        }
                    }
                })
            }
            newOriginProducts[p.id] = Object.assign({}, loadedProduct)

            return editedProduct
        })

        commit('setProducts', flatProducts)
        commit('setOriginProducts', newOriginProducts)

        const { scoreCriteria } = getters
        if (scoreCriteria.length > 0) {
            dispatch('updateScore')
            dispatch('updateOriginScore')
        }
        commit('setLoading', false)
    },
    updateScoreForProduct({ state, getters, commit }, payload: { id: number }) {
        const { products, selectedSegment } = state
        const { scoreCriteria, relevantScoreCriteria } = getters
        const product = products.find(p => p.id === payload.id)

        if (product && scoreCriteria.length > 0) {
            scoreCriteria.forEach((c: TableCriteriaItem) => {
                commit('setProductValue', {
                    path: c.id,
                    id: payload.id,
                    value: scoreUtils.calculateScore(product, relevantScoreCriteria, c, selectedSegment)
                })
            })
        }
    },
    updateScore({ state, getters, commit }) {
        const { products, selectedSegment } = state
        const { scoreCriteria, relevantScoreCriteria } = getters

        if (scoreCriteria.length > 0) {
            const productsWithScore: Product[] = []
            products.forEach(product => {
                const newProduct = Object.assign({}, product)
                scoreCriteria.forEach((c: TableCriteriaItem) => {
                    newProduct[c.id] = scoreUtils.calculateScore(product, relevantScoreCriteria, c, selectedSegment)
                })
                productsWithScore.push(newProduct)
            })
            commit('setProducts', productsWithScore)
        }
    },
    updateOriginScore({ state, getters, commit }) {
        const { originProducts, selectedSegment } = state
        const { scoreCriteria, relevantScoreCriteria } = getters

        if (scoreCriteria.length > 0) {
            const newOriginProducts: KeyMap<Product> = {}
            Object.keys(originProducts).forEach(id => {
                newOriginProducts[id] = Object.assign({}, originProducts[id])
                scoreCriteria.forEach((c: TableCriteriaItem) => {
                    newOriginProducts[id][c.id] = scoreUtils.calculateScore(
                        originProducts[id],
                        relevantScoreCriteria,
                        c,
                        selectedSegment
                    )
                })
            })
            commit('setOriginProducts', newOriginProducts)
        }
    },
    async saveProducts({ state, commit, dispatch }, idList: number[]) {
        const editedProducts = state.products.filter(p => idList.includes(p.id))
        commit('setLoading', true)

        const mutation = buildMutationFromProducts(
            state.selectedCriteria,
            state.selectedTechnology,
            state.selectedSegment,
            editedProducts,
            state.originProducts
        )

        const response = await apolloClient.mutate({
            mutation: mutation.gql,
            variables: {
                products: mutation.productMutations
            }
        })
        await dispatch('loadProducts')
        return response.data
    }
}

export default {
    state: mpeState,
    getters: mpeGetters,
    mutations,
    actions
}
