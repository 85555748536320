export const normalizeUser = response => {
    /* eslint-disable-next-line no-unused-vars */
    const { _links, _embedded, ...data } = response

    const roleAssignments =
        _embedded && _embedded.roleAssignments
            ? _embedded.roleAssignments.map(ra => {
                  const { marketSegment, productGroup, scope } = ra.validity
                  return {
                      roleId: ra.role.id,
                      responsibilityType: ra.responsibilityType,
                      validity: {
                          marketSegment: marketSegment ? String(marketSegment.id) : null,
                          productGroup: productGroup ? productGroup.backendName : null,
                          scope
                      }
                  }
              })
            : []
    return { ...data, roleAssignments }
}

export default normalizeUser
