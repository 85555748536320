import Vue from 'vue'
import { handleFetchResponse } from '@/utils'

const authState = () => ({
    username: '',
    password: '',
    isLoggingIn: false,
    currentUser: null
})

const hasRole = (user, role) => user && user.roles.map(r => r.name).indexOf(role) > -1

const getters = {
    isLoggedIn: ({ currentUser }) => {
        return !!currentUser
    },
    allowedActions: ({ currentUser }) => {
        if (!currentUser) {
            return {}
        }
        const globals = ['ACCESS_ARCHIVE', 'ACCESS_MY_OPEN_TASKS', 'SEARCH_PRODUCT', 'VIEW_RESPONSIBILITIES']
        const edits = [
            'EDIT_BASIC_PRODUCT_INFORMATION',
            'EDIT_GLOBAL_TECHNICAL_RATING',
            'EDIT_MARKET_SEGMENT_RATING',
            'EDIT_PICTURES_AND_VIDEOS',
            'EDIT_REGULATORY_INFORMATION',
            'EDIT_PRODUCT_STATUS',
            'EDIT_MARKETING_RATING'
        ]
        return currentUser.roles.reduce((collection, role) => {
            role.permissions.forEach(p => {
                if (globals.indexOf(p) > -1 || role.scope === 'GLOBAL') {
                    collection[p] = true
                }
                if (edits.indexOf(p) > -1) {
                    if (role.scope === 'MARKET_SEGMENT') {
                        collection[p] = collection[p] || {}
                        if (collection[p] !== true) {
                            collection[p].marketSegments = role.validity.marketSegment
                        }
                    } else if (role.scope === 'PRODUCT_GROUP') {
                        collection[p] = collection[p] || {}
                        if (collection[p] !== true) {
                            collection[p].productGroups = role.validity.productGroup
                        }
                    }
                    collection.EDIT_PRODUCT = true
                }
            })
            return collection
        }, {})
    },
    restrictedProductGroups: ({ currentUser }) => {
        if (!currentUser) {
            return []
        }
        return currentUser.roles.reduce((list, role) => {
            if (role.scope === 'PRODUCT_GROUP') {
                list = list.concat(role.validity.productGroup)
            }
            return list
        }, [])
    },
    restrictedMarketSegments: ({ currentUser }) => {
        if (!currentUser) {
            return []
        }
        return currentUser.roles.reduce((list, role) => {
            if (role.scope === 'MARKET_SEGMENT' && role.name !== 'Marketing Expert') {
                list = list.concat(role.validity.marketSegment)
            }
            return list
        }, [])
    },
    restrictedRootMarketSegments: ({ currentUser }) => {
        if (!currentUser) {
            return []
        }
        return currentUser.roles.reduce((list, role) => {
            if (role.scope === 'MARKET_SEGMENT' && role.name === 'Marketing Expert') {
                list = list.concat(role.validity.marketSegment)
            }
            return list
        }, [])
    },
    isAdministrator: ({ currentUser }) => hasRole(currentUser, 'Administrator'),
    isGlobalTechnicalExpert: ({ currentUser }) => hasRole(currentUser, 'Global Technical Expert'),
    isSAPExpert: ({ currentUser }) => hasRole(currentUser, 'SAP Expert')
}

const mutations = {
    set(state, payload) {
        const { key, value } = payload
        Vue.set(state, key, value)
    },
    setCurrentUser(state, user) {
        Vue.set(state, 'currentUser', user)
    }
}

const actions = {
    login({ state, commit, dispatch }) {
        commit('set', { key: 'isLoggingIn', value: true })

        const { username, password } = state
        const options = {
            method: 'POST',
            body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Sec-Fetch-Mode': 'navigate',
                'Sec-Fetch-User': '?1',
                'Sec-Fetch-Site': 'same-origin'
            }
        }

        return fetch('/login', options)
            .then(handleFetchResponse)
            .then(() => {
                return dispatch('fetchCurrentUser')
            })
            .catch(err => {
                if (err.status === 401) {
                    return Promise.reject({ error: 'Unauthorized', message: 'Username or password not correct' })
                }

                return Promise.reject({ error: err.status, message: err.statusText })
            })
            .finally(() => {
                return commit('set', { key: 'isLoggingIn', value: false })
            })
    },
    fetchCurrentUser({ commit }) {
        return fetch('/api/users/current')
            .then(handleFetchResponse)
            .then(data => {
                const { firstName, lastName, email, roleAssignments } = data
                const roles = roleAssignments.reduce((list, r) => {
                    const { defaultScope, name, permissions } = r.role
                    const { marketSegment, productGroup } = r.validity
                    let updatedExisting = false

                    list.forEach(existing => {
                        if (existing.name === name) {
                            if (marketSegment) {
                                existing.validity.marketSegment.push(marketSegment.name)
                            } else if (productGroup) {
                                existing.validity.productGroup.push(productGroup.backendName)
                            }
                            updatedExisting = true
                        }
                    })

                    if (!updatedExisting) {
                        list.push({
                            name: name,
                            scope: defaultScope,
                            permissions: permissions.map(p => p.action),
                            validity: {
                                marketSegment: marketSegment ? [marketSegment.name] : null,
                                productGroup: productGroup ? [productGroup.backendName] : null
                            }
                        })
                    }

                    return list
                }, [])

                return commit('setCurrentUser', { name: `${firstName} ${lastName}`, email, roles })
            })
            .catch(err => {
                commit('setCurrentUser', null)
                return Promise.reject(err)
            })
    }
}

export default {
    state: authState,
    getters,
    mutations,
    actions
}
