import { handleFetchResponse, sortByProperty } from '@/utils'
import tableInfo from './tableInfo'

const fetchConfig = { headers: { Accept: 'application/schema+json' } }

const fetchPart = (name, url) => {
    return fetch(url, fetchConfig)
        .then(handleFetchResponse)
        .then(res => {
            const data = { name, title: res.title, segmented: false, inCompletenessCheck: false, fields: [] }
            const ignoredProps = ['id', 'product', 'productId', 'technology']
            Object.keys(res.properties).forEach(propName => {
                if (ignoredProps.indexOf(propName) < 0) {
                    if (propName === 'marketSegment') {
                        data.segmented = true
                    } else if (propName === '_isFilledCompletely') {
                        data.inCompletenessCheck = true
                    } else {
                        const { title, type, readOnly, format, enum: enumValues } = res.properties[propName]
                        data.fields.push({ name: propName, title, type, format, enumValues, readOnly })
                    }
                }
            })
            return data
        })
}

export const initializeTables = () => {
    const tables = {}
    const translator = {}
    return fetch('/api/profile/products', fetchConfig)
        .then(handleFetchResponse)
        .then(res => {
            if (!res.properties) {
                // TODO Error Handling !
                return
            }
            Object.keys(res.properties).forEach(name => {
                const property = res.properties[name]
                if (!property.format || property.format !== 'uri' || !property.description) {
                    return
                }
                const apiName = property.description.replace(/.*\/([^/]+)$/, '$1')
                tables[name] = { name, apiName, title: property.title }
                translator[apiName] = name

                if (tableInfo[apiName]) {
                    tables[name] = Object.assign(tables[name], tableInfo[apiName])
                }
            })
        })
        .then(() => fetch('/api/profile/', fetchConfig))
        .then(handleFetchResponse)
        .then(res => {
            const promises = res.links.reduce((list, link) => {
                if (['self', 'backendFrontendDictionaries', 'marketsegments', 'products'].indexOf(link.rel) < 0) {
                    const url = link.href.replace(/^(?:\/\/|[^/]+)*\//, '/')
                    list.push(fetchPart(link.rel, url))
                }
                return list
            }, [])
            return Promise.all(promises).then(results => {
                results.forEach(tableDetails => {
                    const tableName = tableDetails.name
                    if (!translator[tableName]) {
                        return
                    }
                    const table = tables[translator[tableName]]
                    if (!table) {
                        return
                    }
                    table.inCompletenessCheck = tableDetails.inCompletenessCheck
                    table.segmented = tableDetails.segmented
                    table.fields = tableDetails.fields
                    const firstFieldData = table.fields[0]
                    switch (firstFieldData.type) {
                        case 'string':
                            if (table.fields[0].enumValues) {
                                table.enumValues = table.fields[0].enumValues
                            }
                            break
                        case 'boolean':
                            table.enumValues = [true, false]
                            break
                    }
                    if (!table.enumValues) {
                        table.enumValues = false
                    }
                })
            })
        })
        .then(() => {
            return tables
        })
}

export const initializeSegments = () => {
    return fetch('/api/marketSegments?size=1000')
        .then(handleFetchResponse)
        .then(data => {
            const segments = []
            const rootSegments = []
            data._embedded.marketSegments.forEach(seg => {
                if (seg.childOf === 'None') {
                    return
                }
                const segmentData = {
                    childOf: seg.childOf,
                    value: String(seg.id),
                    text: seg.name
                }
                if (seg.childOf === 'global') {
                    rootSegments.push(segmentData)
                } else {
                    segments.push(segmentData)
                }
            })
            return {
                segments: segments.sort(sortByProperty('text')),
                rootSegments: rootSegments.sort(sortByProperty('text'))
            }
        })
}

export const initializeProductGroups = () => {
    return fetch('/api/productGroups?size=1000')
        .then(handleFetchResponse)
        .then(data => {
            const groups = data._embedded.productGroups
                .filter(group => group.fromFormulationFinder === false)
                .map(group => {
                    return {
                        value: group.backendName,
                        text: group.officialName
                    }
                })
            return groups.sort(sortByProperty('text'))
        })
}

export const initializeTechnologies = () => {
    return fetch('/api/technologyKeys?size=20')
        .then(handleFetchResponse)
        .then(data => {
            const technologies = data._embedded.technologyKeys.map(tech => {
                return {
                    value: tech.id,
                    text: tech.name
                }
            })
            return technologies.sort(sortByProperty('text'))
        })
}

export default {
    initializeProductGroups,
    initializeSegments,
    initializeTables,
    initializeTechnologies
}
