import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import configureMediator from './mediator'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules,
    state: () => {},
    mutations: {},
    actions: {}
})

configureMediator(store)

export default store
