<template>
    <v-app id="dbui">
        <navigation v-model="showNavi" :version-info="versionString" />

        <v-app-bar app color="white">
            <v-app-bar-nav-icon @click.stop="showNavi = !showNavi" />
            <v-toolbar-title v-t="'app.title'" class="headline text-uppercase" />
            <v-spacer></v-spacer>
            <router-link :to="{ name: 'home' }" class="home-link mr-6">
                <img :src="require('@/assets/evonik-logo.svg')" height="90%" />
            </router-link>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <loading-spinner v-if="isLoading" overlay />
    </v-app>
</template>

<script>
import Navigation from './components/Navigation'
import LoadingSpinner from './components/LoadingSpinner'
import { mapState } from 'vuex'

export default {
    name: 'ProductManagerApp',
    components: {
        LoadingSpinner,
        Navigation
    },
    data: () => ({
        showNavi: null,
        /* eslint-disable-next-line no-undef */
        versionString: `v ${AppVersion}, git ${String(GitHash).substring(0, 7)}`
    }),
    computed: {
        ...mapState('product', ['isFetchingProduct']),
        isLoading() {
            return this.isFetchingProduct
        }
    }
}
</script>

<style lang="scss">
.v-app-bar .home-link {
    flex: 0 0 120px;
    height: 80%;
}

.noty_layout {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}
</style>
