import { marketingRatingMultipliers } from '@/components/multiple-products-editor/score/scoreInformationConfig'

const isRegulatoryCompliant = (product: Product, criteriaItems: TableCriteriaItem[]) => {
    const regulatoryValues = criteriaItems.map(c => product[c.id])
    return !['NOT_COMPLIANT', 'UNSET', 'Yes'].some(v => regulatoryValues.includes(v))
}

const applyMarketingMultiplier = (product: Product, score: number, selectedSegment: SchemaItem | null) => {
    if (selectedSegment) {
        const marketingRating = product[`marketings.${selectedSegment.value}`]
        const ratingMapping: { [key: string]: number | undefined } = marketingRatingMultipliers
        const multiplier = ratingMapping[marketingRating] === undefined ? 0 : (ratingMapping[marketingRating] as number)
        return score * multiplier
    }
    return score
}

const calculateScoreForProduct = (product: Product, criteriaItems: TableCriteriaItem[]) => {
    const criteriaScores: number[] = []
    criteriaItems.forEach(item => {
        const { enum: enumValues, id } = item
        const productValue = product[id]

        const zeroValues = ['UNSET', 'NONE', 'NOT_APPLICABLE', 'NOT_RECOMMENDED']

        if (!productValue || zeroValues.includes(productValue)) {
            criteriaScores.push(0)
            return
        }

        if (enumValues) {
            const negativeValues = enumValues.filter(e => (e.value as string).includes('NEGATIVE')).reverse()
            const relevantValues = enumValues.filter(
                e => !zeroValues.includes(e.value.toString()) && !(e.value as string).includes('NEGATIVE')
            )

            const scale = relevantValues.length
            let scaleIndex = relevantValues.findIndex(val => val.value === productValue) + 1
            if (negativeValues.length > 0 && scaleIndex === 0) {
                const negativeIndex = negativeValues.findIndex(val => val.value === productValue) + 1
                scaleIndex = negativeIndex * -1
            }

            criteriaScores.push(scaleIndex / scale)
        } else {
            criteriaScores.push(0)
        }
    })
    return criteriaScores.reduce((a, b) => a + b, 0) / criteriaScores.length
}

const calculateScore = (
    product: Product,
    relevantScoreCriteria: TableCriteriaItem[],
    currentCriteria: TableCriteriaItem,
    selectedSegment: SchemaItem | null
) => {
    if (relevantScoreCriteria.length === 0) {
        return 0
    } else {
        const score = calculateScoreForProduct(product, relevantScoreCriteria)
        return currentCriteria.id === 'final_score' ? applyMarketingMultiplier(product, score, selectedSegment) : score
    }
}

export default {
    isRegulatoryCompliant,
    calculateScoreForProduct,
    applyMarketingMultiplier,
    calculateScore
}
