export const preSelection = ['name', 'productGroupWebsite', 'segmentation', 'displayOnCoatinoWebsite']

const excludeColumns = ['_isFilledCompletely', 'product', 'productId', 'id', 'marketSegment', 'technology']

const contextBarConfig: CategoryItem[] = [
    {
        id: 'basic-product-properties',
        name: 'Basic product properties',
        hasGroups: false,
        searchBar: false,
        segmentInformationControls: false,
        editScope: 'EDIT_BASIC_PRODUCT_INFORMATION',
        tableNames: ['products'],
        tableOptions: [
            {
                tableName: 'products',
                strictIncludeColumns: ['name', 'id', 'msdsId', 'productGroupWebsite', 'segmentation']
            }
        ]
    },
    {
        id: 'global-technical-information',
        name: 'Global technical information',
        hasGroups: true,
        searchBar: true,
        segmentInformationControls: false,
        editScope: 'EDIT_GLOBAL_TECHNICAL_RATING',
        tableNames: ['products', 'solubilities', 'technologies', 'productGroupOverviews'],
        tableOptions: [
            {
                tableName: 'products',
                strictIncludeColumns: ['description']
            }
        ],
        excludeColumns
    },
    {
        id: 'regulatory-information',
        name: 'Regulatory information',
        hasGroups: true,
        searchBar: true,
        segmentInformationControls: false,
        editScope: 'EDIT_REGULATORY_INFORMATION',
        tableNames: ['countryRegulations', 'ecoLabels', 'foodContacts', 'toySafeties', 'specialIngredients'],
        excludeColumns
    },
    {
        id: 'marketing-information',
        name: 'Marketing information',
        hasGroups: true,
        searchBar: true,
        segmentInformationControls: false,
        editScope: 'EDIT_MARKETING_RATING',
        tableNames: ['products', 'marketings'],
        tableOptions: [
            {
                tableName: 'products',
                strictIncludeColumns: ['displayOnCoatinoWebsite']
            }
        ],
        excludeColumns
    },
    // In case that communication information should be able to be edited in table view
    // {
    //     id: 'communication-information',
    //     name: 'Communication information',
    //     hasGroups: true,
    //     searchBar: true,
    //     segmentInformationControls: false,
    //     tableNames: ['mediaLinks'],
    //     excludeColumns: excludedColumns
    // },
    {
        id: 'segment-information',
        name: 'Segment information',
        hasGroups: true,
        searchBar: true,
        segmentInformationControls: true,
        editScope: 'EDIT_MARKET_SEGMENT_RATING',
        tableNames: [
            'applications',
            'binderCompatibilities',
            'coatingLayers',
            'coatingThicknesses',
            'curingMechanisms',
            'effectFunctionalities',
            'formulations',
            'pigmentConcentratesCriteria',
            'pigmentFillerTypes',
            'pigments',
            'substrates'
        ],
        excludeColumns
    }
]

export default contextBarConfig
