export const marketingRatingMultipliers = {
    DENIED: 0,
    UNSET: 0,
    DOWNGRADE: 0.8,
    KEEP: 0.9,
    UPGRADE: 1
}

export const contextBarConfig = [
    {
        categoryId: 'score-information',
        id: 'technical_score',
        text: 'Tec. Score',
        isEditable: true,
        isInEditScope: true,
        isProcessed: true,
        type: 'score'
    },
    {
        categoryId: 'score-information',
        id: 'final_score',
        text: 'Final Score',
        isEditable: true,
        isProcessed: true,
        isInEditScope: true,
        type: 'score'
    }
]
