import Vue from 'vue'
import { handleFetchResponse } from '@/utils'
import { initializeProductGroups, initializeSegments, initializeTables, initializeTechnologies } from '@/utils/init'

const productState = () => ({
    tables: {},
    rootSegments: [],
    marketSegments: [],
    productGroups: [],
    technologies: [],
    myOpenTasksCount: 0,
    allOpenTasksCount: 0,
    // TODO remove all occurences of currentProduct
    currentProductIds: [],
    currentProducts: [],
    createProductData: {},
    isFetchingProduct: false,
    isSavingProduct: false,
    isUpdatingProduct: false,
    fetchProductError: null,
    saveProductError: null,
    updateProductError: null
})

const getters = {
    areas: () => ({
        globalTech: ['solubility', 'technology', 'productGroupOverview'],
        regulatory: ['countryRegulation', 'ecoLabel', 'foodContact', 'toySafety', 'specialIngredient'],
        marketing: [],
        segmentedMarketing: ['marketings'],
        communication: [],
        segmentTech: [
            'applications',
            'binderCompatibilities',
            'coatingLayers',
            'coatingThicknesses',
            'curingMechanisms',
            'effectFunctionalities',
            'formulations',
            'pigmentConcentratesCriteria',
            'pigmentFillerTypes',
            'pigments',
            'substrates'
        ]
    }),
    table: state => name => {
        return state.tables[name]
    },
    segmentations: () => {
        return [
            { value: 'GROW', text: 'Grow' },
            { value: 'PUSH', text: 'Push' },
            { value: 'MAINTAIN', text: 'Maintain' },
            { value: 'NEW_PRODUCT', text: 'New Product' }
        ]
    },
    productStates: () => {
        return [
            { value: true, text: 'Online' },
            { value: false, text: 'Offline' }
        ]
    },
    isCompareMode: state => {
        return state.currentProducts && state.currentProducts.length > 1
    }
}

const mutations = {
    set(state, payload) {
        const { key, value } = payload
        Vue.set(state, key, value)
    },
    setTables(state, payload) {
        Vue.set(state, 'tables', Object.assign({}, payload))
    },
    setSegments(state, payload) {
        Vue.set(state, 'marketSegments', payload.slice())
    },
    setRootSegments(state, payload) {
        Vue.set(state, 'rootSegments', payload.slice())
    },
    setProductGroups(state, payload) {
        Vue.set(state, 'productGroups', payload.slice())
    },
    setTechnologies(state, payload) {
        Vue.set(state, 'technologies', payload.slice())
    },
    setCurrentProductIds(state, productIds) {
        state.currentProductIds = productIds
    },
    setCurrentProducts(state, payload) {
        state.currentProducts = payload
    },
    setProductBaseData(state, payload) {
        const index = state.currentProducts.findIndex(
            el => el.base.id === payload.id || el.base.id === payload.productId
        )
        Vue.set(state.currentProducts[index], 'base', Object.assign({}, state.currentProducts[index].base, payload))
    },
    setProductData(state, payload) {
        const { tableName, segmentId, technologyId, data } = payload
        const index = state.currentProducts.findIndex(el => el.base.id === payload.productId)
        const table = state.currentProducts[index].tables[tableName]
        const copy = Object.assign({}, data)
        if (segmentId || String(segmentId) === '0') {
            if (technologyId) {
                if (!table.data[segmentId]) {
                    Vue.set(table.data, segmentId, {})
                }
                Vue.set(table.data[segmentId], technologyId, copy)
            } else {
                Vue.set(table.data, segmentId, copy)
            }
        } else {
            Vue.set(table, 'data', copy)
        }
    },
    setCreateProductData(state, payload) {
        Vue.set(state, 'createProductData', Object.assign({}, payload))
    },
    closeProduct(state, payload) {
        const { productId } = payload
        state.currentProducts = state.currentProducts.filter(el => el.base.id !== productId)
    },
    clearCurrentProduct(state) {
        state.currentProductIds = []
        state.currentProducts = []
    },
    setMedialinksFromApplyToGroup(state, mediaLinks) {
        mediaLinks.forEach(link => {
            const currentProd = state.currentProducts.find(el => el.base.id === link.productId)
            if (currentProd) {
                currentProd.base.mediaLinks.push(link)
            }
        })
    }
}

const transformProductData = data => {
    if (!data._links || !data.name) {
        return null
    }

    /* eslint-disable-next-line no-unused-vars */
    const { self, product, mediaLinks, ..._links } = data._links
    const tables = Object.keys(_links)

    const productData = {
        base: {},
        segments: [],
        tables: {}
    }

    Object.keys(data).forEach(key => {
        if (key === '_links') {
            // ignore
        } else if (tables.indexOf(key) > -1) {
            const rawTableData = data[key]
            const tableData = {
                isSegmented: false,
                hasTechnology: false
            }
            if (Array.isArray(rawTableData)) {
                tableData.isSegmented = true
                if (!rawTableData.length) {
                    tableData.data = {}
                }
                rawTableData.forEach(entry => {
                    if (entry.marketSegment) {
                        /* eslint-disable-next-line no-unused-vars */
                        const { marketSegment, technology, ...rest } = entry
                        const segmentId = marketSegment.id
                        tableData.data = tableData.data || {}
                        if (technology) {
                            tableData.hasTechnology = true
                            tableData.data[segmentId] = tableData.data[segmentId] || {}
                            tableData.data[segmentId][technology.id] = rest
                        } else {
                            tableData.data[segmentId] = rest
                        }
                        if (productData.segments.indexOf(segmentId) < 0) {
                            productData.segments.push(segmentId)
                        }
                    } else {
                        tableData.data = null
                        console.error(`Invalid data for ${key}: missing marketSegment`)
                    }
                })
            } else if (rawTableData == null) {
                tableData.data = {}
            } else if (typeof rawTableData === 'object') {
                /* eslint-disable-next-line no-unused-vars */
                const { productId, ...rest } = rawTableData
                tableData.data = rest
            }
            productData.tables[key] = tableData
        } else {
            productData.base[key] = data[key]
        }
    })

    if (!productData.base.mediaLinks) {
        productData.base.mediaLinks = []
    }

    return productData
}

const actions = {
    fetchCurrentProducts({ state, commit, dispatch }) {
        const { currentProductIds } = state
        commit('set', { key: 'isFetchingProduct', value: true })
        commit('set', { key: 'fetchProductError', value: null })
        // commit('clearCurrentProduct')

        return Promise.all(currentProductIds.map(productId => dispatch('fetchProduct', productId)))
            .then(values => {
                commit('setCurrentProducts', values)
                return values.forEach(() => {
                    commit('set', { key: 'fetchProductError', value: null })
                })
            })
            .catch(err => {
                commit('set', { key: 'fetchProductError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isFetchingProduct', value: false })
            })
    },
    fetchProduct(context, productId) {
        return fetch(`/api/products/${productId}?projection=complete`)
            .then(handleFetchResponse)
            .then(transformProductData)
    },
    fetchTables({ commit }) {
        return initializeTables().then(tables => commit('setTables', tables))
    },
    fetchSegments({ commit }) {
        return initializeSegments().then(({ segments, rootSegments }) => {
            commit('setSegments', segments)
            commit('setRootSegments', rootSegments)
        })
    },
    fetchProductGroups({ commit }) {
        return initializeProductGroups().then(groups => commit('setProductGroups', groups))
    },
    fetchTechnologies({ commit }) {
        return initializeTechnologies().then(technologies => commit('setTechnologies', technologies))
    },
    createProduct({ state, commit }) {
        const options = {
            method: 'POST',
            body: JSON.stringify(state.createProductData),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        commit('set', { key: 'isSavingProduct', value: true })
        commit('set', { key: 'saveProductError', value: null })

        return fetch('/api/products', options)
            .then(handleFetchResponse)
            .catch(err => {
                commit('set', { key: 'saveProductError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isSavingProduct', value: false })
            })
    },
    updateProductBase({ commit }, payload) {
        const { updateData, productId } = payload
        console.log('Update product base', updateData)
        const url = `/api/products/${productId}`
        const options = {
            method: 'PATCH',
            body: JSON.stringify(updateData),
            headers: { 'Content-Type': 'application/json' }
        }

        commit('set', { key: 'isUpdatingProduct', value: true })
        commit('set', { key: 'updateProductError', value: null })

        return fetch(url, options)
            .then(handleFetchResponse)
            .then(response => {
                /* eslint-disable-next-line no-unused-vars */
                const { pdfLinks, _links, ...responseData } = response
                commit('setProductBaseData', responseData)
            })
            .catch(err => {
                commit('set', { key: 'updateProductError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isUpdatingProduct', value: false })
            })
    },
    updateProductData({ state, commit }, payload) {
        const { tableName, segmentId, technologyId, data, productId } = payload
        const apiTableName = state.tables[tableName].apiName
        let url = `/api/${apiTableName}/${productId}`
        if (segmentId || String(segmentId) === '0') {
            url += `_${segmentId}`
        }
        if (technologyId) {
            url += `_${technologyId}`
        }
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        }

        commit('set', { key: 'isUpdatingProduct', value: true })
        commit('set', { key: 'updateProductError', value: null })

        return fetch(url, options)
            .then(handleFetchResponse)
            .then(response => {
                const { ...responseData } = response
                const responseProductId = response.productId ? response.productId : response.id.productId
                commit('setProductData', {
                    productId: responseProductId,
                    tableName,
                    segmentId,
                    technologyId,
                    data: responseData
                })
            })
            .catch(err => {
                commit('set', { key: 'updateProductError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isUpdatingProduct', value: false })
            })
    },
    updateMediaLinks({ commit }, payload) {
        const { productId, updateData } = payload

        const options = {
            method: 'PUT',
            body: JSON.stringify(updateData),
            headers: { 'Content-Type': 'application/json' }
        }

        commit('set', { key: 'isUpdatingProduct', value: true })
        commit('set', { key: 'updateProductError', value: null })

        return fetch(`/api/products/${productId}/mediaLinks/`, options)
            .then(handleFetchResponse)
            .then(response => {
                /* eslint-disable-next-line no-unused-vars */
                commit('setProductBaseData', { mediaLinks: response, productId })
            })
            .catch(err => {
                commit('set', { key: 'updateProductError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isUpdatingProduct', value: false })
            })
    },
    fetchMyOpenTasksCount({ commit }) {
        return fetch('/api/products/search/myOpen?size=1')
            .then(handleFetchResponse)
            .then(data => {
                const count = data.page.totalElements
                commit('set', { key: 'myOpenTasksCount', value: count })
                return count
            })
            .catch(() => {})
    },
    fetchAllOpenTasksCount({ commit }) {
        return fetch('/api/products/search/open?size=1')
            .then(handleFetchResponse)
            .then(data => {
                const count = data.page.totalElements
                commit('set', { key: 'allOpenTasksCount', value: count })
                return count
            })
            .catch(() => {})
    },
    markTechnologiesIrrelevant(context, payload) {
        const { segmentId, technologyIds, productId } = payload
        const qs = `marketSegments=${segmentId}&technologies=${technologyIds.join(',')}`
        const url = `/api/products/${productId}/markIrrelevant?${qs}`
        return fetch(url, { method: 'POST' }).then(handleFetchResponse)
    },
    assignMediaLinkToAllProductsOfGroup({ commit }, payload) {
        const { productId, mediaLinkId } = payload
        const url = `api/products/${productId}/mediaLinks/${mediaLinkId}/assignToAllProductsOfGroup`
        return fetch(url, { method: 'POST' })
            .then(handleFetchResponse)
            .then(data => {
                commit('setMedialinksFromApplyToGroup', data)
                return data
            })
    }
}

export default {
    state: productState,
    getters,
    mutations,
    actions
}
