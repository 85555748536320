const checkNotAuthenticated = (response: Response) => {
    if (response.status === 401 && !response.url.match(/\/users\/current$/) && !response.url.match(/\/login$/)) {
        window.router.gotoLogin()
    }
}

const fetchWithAuthCheck = async (input: RequestInfo, init?: RequestInit) => {
    const response = await fetch(input, init)
    checkNotAuthenticated(response)
    return response
}

export default fetchWithAuthCheck
