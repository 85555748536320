export default function configureMediator(store) {
    // listen to mutations
    store.subscribe(({ type, payload }) => {
        switch (type) {
            case 'auth/setCurrentUser':
                if (payload != null) {
                    store.commit('product/set', { key: 'myOpenTasksCount', value: 0 })
                    store.commit('product/set', { key: 'allOpenTasksCount', value: 0 })
                    //
                    store.dispatch('product/fetchTables')
                    store.dispatch('product/fetchSegments')
                    store.dispatch('product/fetchProductGroups')
                    store.dispatch('product/fetchTechnologies')
                } else if (payload == null) {
                    store.commit('product/set', { key: 'myOpenTasksCount', value: 0 })
                    store.commit('product/set', { key: 'allOpenTasksCount', value: 0 })
                }
        }
    })

    // listen to actions
    // note: doesn't not wait for the result of async actions
    store.subscribeAction((/* { type, payload }, state */) => {
        // switch (type) {
        //     case 'auth/signOut':
        //         return router.push('/auth/signin')
        // }
    })
}
