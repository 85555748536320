import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: {
        themes: {
            light: {
                deeppurple: '#991d85',
                white: '#ffffff',
                warmgray: '#dad5c9',
                black: '#000000',
                green: '#78be20',
                petrol: '#008c95',
                orange: '#dc6b2f',
                red: '#9b2743',
                //
                primary: '#991d85',
                secondary: '#dad5c9',
                accent: '#000000',
                success: '#78be20',
                info: '#008c95',
                warning: '#dc6b2f',
                error: '#9b2743'
            }
        }
    }
})
