import router from '../router'
import store from '../store'

const checkNotAuthenticated = (response: Response) => {
    if (response.status === 401 && !response.url.match(/\/users\/current$/) && !response.url.match(/\/login$/)) {
        store.commit('auth/setCurrentUser', null)
        router.gotoLogin()
    }
}

const handleJSONResponse = (response: Response) => {
    return response.json().then(json => {
        if (response.ok) {
            return json
        } else {
            checkNotAuthenticated(response)
            const err = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText
            })
            return Promise.reject(err)
        }
    })
}

const handleTextResponse = (response: Response) => {
    return response.text().then(text => {
        if (response.ok) {
            return text
        } else {
            checkNotAuthenticated(response)
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
                errorMessage: text
            })
        }
    })
}

export const extractAndDisplayError = (err: CustomErrorObject, noty: NotyObj, timeout?: number) => {
    let msg
    if (!err) {
        msg = 'An unknown error occurred. Please try again later.'
    } else if (err.errors) {
        msg = err.errors.map(e => (e.title ? `${e.title}<br />${e.detail}` : e.detail)).join('<br />')
    } else if (err.error && err.message) {
        msg = `${err.error}<br />${err.message}`
    } else if (err.message) {
        msg = err.message
    } else {
        msg = `An unknown error occurred. Please try again later. Error: ${err}`
    }
    noty.error(msg, { timeout: timeout || 8000 })
}

export const handleFetchResponse = (response: Response) => {
    const ct = response.headers.get('content-type')
    if (
        ct &&
        (ct.includes('application/json') ||
            ct.includes('application/hal+json') ||
            ct.includes('application/schema+json'))
    ) {
        return handleJSONResponse(response)
    } else {
        return handleTextResponse(response)
    }
}

export const plainObjectsEqual = (a: KeyMap<unknown>, b: KeyMap<unknown>) => {
    if (a === b) {
        return true
    }
    const aKeys = Object.keys(a).sort()
    const bKeys = Object.keys(b).sort()

    if (aKeys.join(',') !== bKeys.join(',')) {
        return false
    }

    return aKeys.every(key => {
        return a[key] === b[key]
    })
}

export const sortByProperty = (property: string) => (a: KeyMap<string>, b: KeyMap<string>) => {
    const aVal = a[property].toUpperCase()
    const bVal = b[property].toUpperCase()
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
}

export const isNumberBetween = (val: number, boundA: number, boundB: number) => {
    const min = Math.min(boundA, boundB)
    const max = Math.max(boundA, boundB)
    return val >= min && val <= max
}

export const toSingular = (name: string) => {
    if (/ies$/.test(name)) {
        return name.replace(/ies$/, 'y')
    }
    if (/ses$/.test(name)) {
        return name.replace(/ses$/, 's')
    }
    if (/s$/.test(name)) {
        return name.replace(/s$/, '')
    }
    return name
}

export default {
    extractAndDisplayError,
    handleFetchResponse,
    plainObjectsEqual,
    sortByProperty,
    toSingular
}
