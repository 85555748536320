import Vue from 'vue'
import VueApollo from 'vue-apollo'
import fetch from 'unfetch'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)
// HTTP connection to the API
const httpLink = createHttpLink({
    uri: '/graphql',
    fetch: (fetch as unknown) as WindowOrWorkerGlobalScope['fetch']
})

// GraqphQl request error
const errorLink = onError(({ networkError }) => {
    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
        window.router.gotoLogin()
    }
})

// Cache implementation
const cache = new InMemoryCache({
    addTypename: false
})

// Create the apollo client
const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache'
        },
        query: {
            fetchPolicy: 'no-cache'
        }
    }
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})

export default apolloProvider
export { apolloProvider, apolloClient }
