<template>
    <div v-if="!isLoggedIn">
        <v-card color="primary" tile dark max-width="500" class="mx-auto">
            <v-card-text class="white--text text-center">
                <div v-t="'home.headline'" class="headline mb-2" />
                <div class="centered--text mb-2" v-html="$t('home.notLoggedInHint')" />
                <div class="centered--text" v-html="$t('home.useBrowserNote')" />
            </v-card-text>
            <v-card-actions>
                <v-btn v-t="'home.linkToLogin'" tile text class="mx-auto" @click="$router.gotoLogin()" />
            </v-card-actions>
        </v-card>

        <v-card v-if="userNotFound" color="error" tile dark max-width="500" class="mx-auto mt-8">
            <v-card-text class="white--text text-center">
                <div class="centered--text">
                    {{ $t('home.userNotFound', { username: userNotFound }) }}
                </div>
            </v-card-text>
        </v-card>
    </div>

    <v-container v-else fluid>
        <v-layout align-start>
            <v-flex xs12 sm8 md4 text-center>
                <dashboard-button
                    :disabled="!allowedActions.CREATE_PRODUCT"
                    :icon="svgAddProduct"
                    :to="{ name: 'createProduct' }"
                >
                    <span v-html="$t('home.actions.createProduct')" />
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.EDIT_PRODUCT"
                    :icon="svgEditProduct"
                    :to="{ name: 'myProducts' }"
                >
                    {{ $t('home.actions.editProduct') }}
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.ACCESS_MY_OPEN_TASKS"
                    :icon="svgMyTasks"
                    :badge="allowedActions.ACCESS_MY_OPEN_TASKS ? myOpenTasksCount : null"
                    :to="{ name: 'myOpenProducts' }"
                >
                    {{ $t('home.actions.myOpenTasks') }}
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.SEARCH_PRODUCT"
                    :icon="svgTableEdit"
                    :to="{ name: 'multipleProductsEditor' }"
                >
                    <span v-html="$t('home.actions.multipleProductsEditor')" />
                </dashboard-button>
            </v-flex>
            <v-flex xs12 sm8 md4 text-center>
                <dashboard-button
                    :disabled="!allowedActions.SEARCH_PRODUCT"
                    :icon="svgTable"
                    :to="{ name: 'products' }"
                >
                    <span v-html="$t('home.actions.productGroups')" />
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.VIEW_RESPONSIBILITIES"
                    :icon="svgResponsible"
                    :to="{ name: 'contacts' }"
                >
                    <span v-html="$t('home.actions.responsibilities')" />
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.ACCESS_ARCHIVE"
                    :icon="svgArchive"
                    :to="{ name: 'archive' }"
                >
                    {{ $t('home.actions.archive') }}
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.EDIT_REGULATORY_INFORMATION"
                    :icon="svgCountryInventory"
                    :to="{ name: 'countryInventory' }"
                >
                    <span v-html="$t('home.actions.countryInventory')" />
                </dashboard-button>
            </v-flex>
            <v-flex xs12 sm8 md4 text-center>
                <dashboard-button
                    :disabled="!allowedActions.CREATE_USER"
                    :icon="svgAddUser"
                    :to="{ name: 'createUser' }"
                >
                    {{ $t('home.actions.createUser') }}
                </dashboard-button>
                <dashboard-button :disabled="!allowedActions.EDIT_USER" :icon="svgEditUser" :to="{ name: 'users' }">
                    {{ $t('home.actions.editUser') }}
                </dashboard-button>
                <dashboard-button
                    :disabled="!allowedActions.VIEW_ALL_PENDING_TASKS"
                    :icon="svgAllOpenProducts"
                    :badge="allowedActions.VIEW_ALL_PENDING_TASKS ? allOpenTasksCount : null"
                    :to="{ name: 'allOpenProducts' }"
                >
                    <span v-html="$t('home.actions.allOpenProducts')" />
                </dashboard-button>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
    mdiAccountEdit,
    mdiAccountMultiple,
    mdiAccountPlus,
    mdiAlertBox,
    mdiArchive,
    mdiCubeOutline,
    mdiEarthBox,
    mdiHome,
    mdiHomeAlert,
    mdiLogin,
    mdiPlusBox,
    mdiSquareEditOutline,
    mdiTableEdit,
    mdiTableLarge
} from '@mdi/js'
import DashboardButton from '../components/DashboardButton'

export default {
    name: 'Home',
    components: {
        DashboardButton
    },
    data: () => ({
        svgAddProduct: mdiPlusBox,
        svgAddUser: mdiAccountPlus,
        svgAllOpenProducts: mdiHomeAlert,
        svgArchive: mdiArchive,
        svgCountryInventory: mdiEarthBox,
        svgCube: mdiCubeOutline,
        svgEditProduct: mdiSquareEditOutline,
        svgEditUser: mdiAccountEdit,
        svgHome: mdiHome,
        svgLogin: mdiLogin,
        svgMyTasks: mdiAlertBox,
        svgResponsible: mdiAccountMultiple,
        svgTableEdit: mdiTableEdit,
        svgTable: mdiTableLarge
    }),
    computed: {
        ...mapGetters('auth', ['allowedActions', 'isLoggedIn']),
        ...mapState('product', ['allOpenTasksCount', 'myOpenTasksCount']),
        userNotFound() {
            const { query } = this.$route
            if (query && query.userNotFound && query.userNotFound !== '') {
                return query.userNotFound
            }
            return false
        }
    },
    mounted() {
        if (this.isLoggedIn) {
            if (this.allowedActions.ACCESS_MY_OPEN_TASKS) {
                this.$store.dispatch('product/fetchMyOpenTasksCount')
            }
            if (this.allowedActions.VIEW_ALL_PENDING_TASKS) {
                this.$store.dispatch('product/fetchAllOpenTasksCount')
            }
        }
    }
}
</script>
