import Vue from 'vue'
import { handleFetchResponse } from '@/utils'
import { normalizeUser } from '@/utils/normalize'

const userState = () => ({
    users: [],
    roles: [],
    totalUsersCount: 0,
    currentUser: null,
    createUserData: {},
    isFetchingUsers: false,
    isFetchingUser: false,
    isSavingUser: false,
    fetchUserError: null,
    saveUserError: null
})

const getters = {}

const mutations = {
    set(state, payload) {
        const { key, value } = payload
        Vue.set(state, key, value)
    },
    setCurrentUser(state, payload) {
        state.currentUser = Object.assign({}, payload)
    },
    clearCurrentUser(state) {
        state.currentUser = null
    },
    setCreateUserData(state, payload) {
        state.createUserData = Object.assign({}, payload)
    }
}

const actions = {
    fetchUsers({ commit }, params) {
        commit('set', { key: 'isFetchingUsers', value: true })
        return fetch(`/api/users?${params}`)
            .then(handleFetchResponse)
            .then(data => {
                const users = data._embedded.users.map(user => {
                    const { id, firstName, lastName, email } = user
                    const roles = []
                    user.roleAssignments.forEach(role => {
                        if (roles.indexOf(role.role.name) < 0) {
                            roles.push(role.role.name)
                        }
                    })
                    return { id, firstName, lastName, email, role: roles.sort() }
                })
                const totalCount = data.page.totalElements
                commit('set', { key: 'users', value: users })
                commit('set', { key: 'totalUsersCount', value: totalCount })
            })
            .finally(() => {
                commit('set', { key: 'isFetchingUsers', value: false })
            })
    },
    fetchRoles({ commit }) {
        return fetch('/api/roles')
            .then(handleFetchResponse)
            .then(data => {
                const roles = data._embedded.roles.map(role => {
                    const { id, name, defaultScope } = role
                    return { id, name, defaultScope }
                })
                commit('set', { key: 'roles', value: roles })
            })
    },
    fetchUser({ commit }, userId) {
        commit('set', { key: 'isFetchingUser', value: true })
        commit('set', { key: 'fetchUserError', value: null })

        return fetch(`/api/users/${userId}`)
            .then(handleFetchResponse)
            .then(normalizeUser)
            .then(data => {
                commit('set', { key: 'fetchUserError', value: null })
                commit('setCurrentUser', data)
            })
            .catch(err => {
                commit('setCurrentUser', null)
                commit('set', { key: 'fetchUserError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isFetchingUser', value: false })
            })
    },
    createUser({ commit }, payload) {
        const options = {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' }
        }

        commit('set', { key: 'isSavingUser', value: true })
        commit('set', { key: 'saveUserError', value: null })

        return fetch('/api/users', options)
            .then(handleFetchResponse)
            .catch(err => {
                commit('set', { key: 'saveUserError', value: err })
                return Promise.reject(err)
            })
            .finally(() => {
                commit('set', { key: 'isSavingUser', value: false })
            })
    },
    updateUser({ commit, state }, payload) {
        commit('set', { key: 'isSavingUser', value: true })

        const { currentUser } = state
        const options = {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' }
        }

        return fetch(`/api/users/${currentUser.id}`, options)
            .then(handleFetchResponse)
            .then(normalizeUser)
            .then(response => commit('setCurrentUser', response))
            .finally(() => commit('set', { key: 'isSavingUser', value: false }))
    },
    async deleteUser(store, id) {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const response = await fetch(`/api/users/${id}`, options)
        return handleFetchResponse(response)
    }
}

export default {
    state: userState,
    getters,
    mutations,
    actions
}
