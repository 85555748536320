import Home from '@/views/Home.vue'
import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'login' */ '@/views/Login.vue'),
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: 'products' */ '@/views/Products.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products/my',
        name: 'myProducts',
        component: () => import(/* webpackChunkName: 'products' */ '@/views/MyProducts.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products/my-open',
        name: 'myOpenProducts',
        component: () => import(/* webpackChunkName: 'products' */ '@/views/MyTasks.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'ACCESS_MY_OPEN_TASKS'
        }
    },
    {
        path: '/products/all-open',
        name: 'allOpenProducts',
        component: () => import(/* webpackChunkName: 'products' */ '@/views/AllTasks.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'VIEW_ALL_PENDING_TASKS'
        }
    },
    {
        path: '/archive',
        name: 'archive',
        component: () => import(/* webpackChunkName: 'products' */ '@/views/Archive.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'ACCESS_ARCHIVE'
        }
    },
    {
        path: '/create-product',
        name: 'createProduct',
        component: () => import(/* webpackChunkName: 'product' */ '@/views/CreateProduct.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'CREATE_PRODUCT'
        }
    },
    {
        path: '/product/:id',
        name: 'product',
        component: () => import(/* webpackChunkName: 'product' */ '@/views/Product.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import(/* webpackChunkName: 'contacts' */ '@/views/Contacts.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'VIEW_RESPONSIBILITIES'
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: 'users' */ '@/views/Users.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'EDIT_USER'
        }
    },
    {
        path: '/user/:id',
        name: 'user',
        component: () => import(/* webpackChunkName: 'users' */ '@/views/User.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'EDIT_USER'
        }
    },
    {
        path: '/create-user',
        name: 'createUser',
        component: () => import(/* webpackChunkName: 'users' */ '@/views/CreateUser.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'CREATE_USER'
        }
    },
    {
        path: '/country-inventory',
        name: 'countryInventory',
        component: () => import(/* webpackChunkName: 'countryInventory' */ '@/views/CountryInventoryMappings.vue'),
        meta: {
            requiresAuth: true,
            restrictedTo: 'EDIT_REGULATORY_INFORMATION'
        }
    },
    {
        path: '/multiple-products-editor',
        name: 'multipleProductsEditor',
        component: () => import(/* webpackChunkName: 'multipleProductsEditor' */ '@/views/MultipleProductsEditor.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/*',
        redirect: '/'
        // TODO: What is better? Go home or show some kind of 404
        // component: NotFoundComponent
    }
]

export default routes
