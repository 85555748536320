import i18n from '@/i18n'

const tableKeyAliases: Record<string, string> = {
    solubilities: 'solubility',
    specialIngredients: 'specialIngredient',
    technologies: 'technology',
    countryRegulations: 'countryRegulation',
    ecoLabels: 'ecoLabel',
    foodContacts: 'foodContact',
    toySafeties: 'toySafety',
    productGroupOverviews: 'productGroupOverview'
}

const determineEditRight = (
    critName: string,
    tableId: string,
    editScope: string,
    isAdministrator: boolean,
    allowedActions: { [key: string]: string }
) => {
    if (critName === 'id' || tableId === 'countryRegulations') {
        return false
    }
    if (isAdministrator) {
        return true
    }
    return !!allowedActions[editScope]
}

const determineMarketingEditScope = (
    currentSegmentId: number,
    allMarketingSegments: SchemaItem[],
    isAdministrator: boolean,
    restrictedRootMarketSegments: string[]
) => {
    if (isAdministrator || (restrictedRootMarketSegments && restrictedRootMarketSegments.length === 0)) {
        return true
    }

    const scope = allMarketingSegments.filter(segment =>
        segment.value === currentSegmentId && segment.childOf
            ? restrictedRootMarketSegments.includes(segment.childOf)
            : false
    )
    return scope.length > 0
}

const determineSegmentEditScope = (
    selectedSegment: SchemaItem | null,
    isAdministrator: boolean,
    restrictedMarketSegments: string[]
) => {
    if (isAdministrator) {
        return true
    }
    if (selectedSegment && restrictedMarketSegments) {
        return restrictedMarketSegments.length === 0 || restrictedMarketSegments.includes(selectedSegment.valueText)
    }
    return false
}

const determineType = (criteriaType: string, criteriaEnum: TextValueItem<string | boolean>[] | undefined) => {
    if (criteriaType === 'boolean' || (criteriaType === 'string' && criteriaEnum)) {
        return 'select'
    }
    if (criteriaType === 'number' || criteriaType === 'integer') {
        return 'number'
    }
    if (criteriaType === 'string' && !criteriaEnum) {
        return 'text'
    }
    return criteriaType
}

const determineEnums = (id: string, type: string, enumValues: TextValueItem<string | boolean>[] | undefined) => {
    if (type === 'boolean') {
        const booleanTypes = id === 'displayOnCoatinoWebsite' ? [true, false] : [true, false, 'UNSET']
        return booleanTypes.map(value => ({
            text: i18n.t(`product.values.${value}`),
            value
        }))
    }

    if (enumValues) {
        const i18key = id === 'segmentation' ? 'segmentations' : 'values'

        // handle yes | no | unset enums
        const isBooleanEnum = enumValues
            .map(e => e.value)
            .every(val => {
                return ['Yes', 'No', 'UNSET'].indexOf(val as string) !== -1
            })
        if (isBooleanEnum) {
            return enumValues.reverse().map(el => ({
                text: i18n.te(`product.${i18key}.${el.value}`) ? i18n.t(`product.${i18key}.${el.value}`) : el.text,
                value: el.value
            }))
        }

        // handle all other enums
        const unsetEnum = enumValues.find(e => e.value === 'UNSET')
        let enumCopy = enumValues.filter(e => e.value !== 'UNSET')

        if (id !== 'segmentation' && unsetEnum && enumCopy) {
            enumCopy = [unsetEnum].concat(enumCopy)
        }

        return enumCopy?.map(el => ({
            text: i18n.te(`product.${i18key}.${el.value}`) ? i18n.t(`product.${i18key}.${el.value}`) : el.text,
            value: el.value
        }))
    }
    return []
}

const determineUnsetValue = (type: string) => type === 'text' ? '' : 'UNSET'

const flattenProduct = (product: Product, criteria: TableCriteriaItem[]) => {
    const result: Product = {
        id: product.id,
        productGroupWebsite: product.productGroupWebsite,
        isSelected: product.isSelected
    }

    criteria.forEach((criterion, i) => {
        if (criterion.type === 'score') {
            return
        }
        const path = criterion.id.split('.')
        const crit = path[0]
        const subCrit = path[1]
        if (!subCrit) {
            const val = product[crit]
            result[crit] = val === null || val === undefined ? determineUnsetValue(criterion.type) : product[crit]
        } else {
            let val
            if (Array.isArray(product[crit])) {
                if (criterion.tableId !== 'marketings') {
                    val = product[crit][0] ? product[crit][0][subCrit] : null
                }
            } else {
                val = product[crit] ? product[crit][subCrit] : null
            }
            result[criterion.id] = val === null || val === undefined ? determineUnsetValue(criterion.type) : val
        }
    })

    // add all requested marketing ratings
    if (product.marketings) {
        product.marketings.forEach(v => {
            if (v.marketSegment) {
                const val = v.marketingRating
                result[`marketings.${v.marketSegment.id}`] = val === null || val === undefined ? 'UNSET' : val
            }
        })
    }

    return result
}

const moveArrayElement = (arr: unknown[], from: number, to: number) => {
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
}

export {
    determineEditRight,
    determineEnums,
    determineMarketingEditScope,
    determineSegmentEditScope,
    determineType,
    flattenProduct,
    moveArrayElement,
    tableKeyAliases
}
