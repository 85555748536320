<template>
    <div class="loading-filler" :class="overlay ? 'overlay' : ''" align-center justify-center>
        <span class="loading-filler--background" />
        <v-progress-circular class="loading-filler--spinner" indeterminate color="primary" />
    </div>
</template>

<script>
export default {
    name: 'LoadingOverlay',
    props: {
        overlay: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.loading-filler {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    top: 0;
    display: flex;

    &.overlay {
        position: fixed;
        z-index: 10;
        left: 0;
        width: 100vw;
    }

    .loading-filler--spinner {
        margin: auto;
    }

    .loading-filler--background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.5;
        animation: fadeIn 0.3s ease-in-out;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
</style>
