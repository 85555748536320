import gql from 'graphql-tag'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'

const buildQueryFromCriteria = (criteria: TableCriteriaItem[], options?: GraphQlQueryOptions) => {
    const allProductsQuery: KeyMap<unknown> = {}

    criteria.forEach(criterion => {
        if (!criterion.isProcessed) {
            const { id, tableId, tableAlias, criteriaName } = criterion
            const table = tableAlias ? tableAlias : tableId

            if (tableId === 'products') {
                allProductsQuery[id] = true
            } else if (tableId === 'marketings') {
                allProductsQuery[table] = {
                    marketingRating: true,
                    marketSegment: {
                        id: true
                    }
                }
            } else {
                if (!allProductsQuery[table]) {
                    allProductsQuery[table] = {}
                }
                allProductsQuery[table][criteriaName] = true
            }
        }
    })
    // as id is needed for operations always set to true
    // productGroupWebsite is always needed to check edit rights for EDIT_GLOBAL_TECHNICAL_RATING
    allProductsQuery.id = true
    allProductsQuery.productGroupWebsite = true
    allProductsQuery.segmentation = true
    allProductsQuery.marketings = {
        marketingRating: true,
        marketSegment: {
            id: true
        }
    }

    const query = {
        query: {
            allProducts: {
                ...allProductsQuery
            }
        }
    }

    if (options) {
        query.query.allProducts.__args = options
    }
    return gql`
        ${jsonToGraphQLQuery(query, { pretty: true })}
    `
}

const buildMutationFromProducts = (
    selectedCriteria: TableCriteriaItem[],
    selectedTechnology: SchemaItem | null,
    selectedSegment: SchemaItem | null,
    products: Product[],
    originProducts: KeyMap<Product>
) => {
    const allProductMutations = products.map(product => {
        const queryJson: KeyMap<unknown> = {}
        const originProduct = originProducts[product.id]

        selectedCriteria.forEach(criterion => {
            if (
                (criterion.id !== 'id' && originProduct[criterion.id] === product[criterion.id]) ||
                criterion.isProcessed
            ) {
                return
            }
            const key = criterion.id
            const pathArray = key.split('.')
            const table = pathArray[0]
            const crit = pathArray[1]

            if (criterion.categoryId === 'marketing-information' && criterion.id !== 'displayOnCoatinoWebsite') {
                if (!queryJson[table]) {
                    queryJson[table] = []
                }
                queryJson[table].push({
                    marketingRating: product[key],
                    id: {
                        segmentId: criterion.marketingId
                    }
                })
            } else if (criterion.categoryId === 'segment-information') {
                if (!queryJson[table]) {
                    queryJson[table] = [
                        {
                            id: {
                                technologyId: selectedTechnology?.value,
                                segmentId: selectedSegment?.value
                            }
                        }
                    ]
                }
                queryJson[table][0][crit] = product[key]
            } else if (!crit) {
                queryJson[table] = product[key]
            } else {
                if (!queryJson[table]) {
                    queryJson[table] = {}
                }
                queryJson[table][crit] = product[key]
            }
        })
        if (!queryJson.id) {
            queryJson.id = product.id
        }
        return queryJson
    })

    return {
        gql: gql`
            mutation($products: [ProductInput]) {
                updateProducts(product: $products)
            }
        `,
        productMutations: allProductMutations
    }
}

const deleteProductsMutation = (ids: number[]) => {
    console.log('delete', ids)
    return {
        mutation: gql`
            mutation($productIds: [Long!]) {
                deleteProducts(productIds: $productIds) {
                    productId
                    statusCode
                }
            }
        `,
        variables: {
            productIds: ids
        }
    }
}

export { buildQueryFromCriteria, buildMutationFromProducts, deleteProductsMutation }
