import Vue from 'vue'
import VueRouter, { RawLocation } from 'vue-router'
import routes from '@/router/routes'
import store from '@/store'

Vue.use(VueRouter)

const router: VueRouter & LoginRouter = Object.assign(new VueRouter({ routes }))

router.gotoLogin = (redirect?: string | (string | null)[] | null | undefined) => {
    if (process.env.VUE_APP_LOGIN_URL && process.env.VUE_APP_LOGIN_URL !== '') {
        const loc = window.encodeURIComponent(window.location.href)
        window.location.href = `${process.env.VUE_APP_LOGIN_URL}?redirect=${loc}`
    } else {
        const route: RawLocation = {
            name: 'login'
        }
        if (redirect) {
            route.query = { redirect }
        }
        router.push(route)
    }
}

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters['auth/isLoggedIn']
    if (router.gotoLogin && to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        return router.gotoLogin(to.fullPath)
    }
    next()
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters['auth/isLoggedIn']
    if (to.matched.some(record => record.meta.requiresGuest) && isLoggedIn) {
        return next({
            path: from.fullPath || '/'
        })
    }
    next()
})

router.beforeEach((to, from, next) => {
    const { restrictedTo } = to.meta
    const allowed = store.getters['auth/allowedActions']
    if (restrictedTo && !allowed[restrictedTo]) {
        return next({
            path: from.fullPath || '/'
        })
    }
    next()
})

window.router = router

export default router
