import { ActionTree, GetterTree, MutationTree } from 'vuex'
import i18n from '@/i18n'
import {
    determineEnums,
    determineType,
    determineEditRight,
    determineSegmentEditScope,
    tableKeyAliases
} from '@/components/multiple-products-editor/store/utils'

import categoryConfig from '@/components/multiple-products-editor/contextBarConfig'
import { contextBarConfig } from '@/components/multiple-products-editor/score/scoreInformationConfig'
import fetchWithAuthCheck from '@/components/utils'

const schemaState = (): SchemaState => ({
    productGroups: [],
    rootSegments: [],
    marketSegments: [],
    technologies: [],
    tableItemsByCategory: {},
    tableItemsFlat: [],
    tableSchemas: {},
    tableSchemasLoaded: false
})

const schemaGetters: GetterTree<SchemaState, RootState> = {
    tableItem: (state, getters, rootState, rootGetters) => (tableId: string, category: CategoryItem) => {
        const { tableSchemas, marketSegments, productGroups } = state
        const { selectedSegment } = rootState.mpe
        const { id: categoryId, editScope } = category

        const tableItems: TableCriteriaItem[] = []
        const tableSchema = tableSchemas[tableId]
        const allowedActions = rootGetters['auth/allowedActions']
        const restrictedMarketSegments = rootGetters['auth/restrictedMarketSegments']
        const isAdministrator: boolean = rootGetters['auth/isAdministrator']

        if (tableSchema && tableSchema.properties) {
            Object.keys(tableSchema.properties).forEach(crit => {
                const critProfile = tableSchema.properties[crit]
                // console.log('check', crit, critProfile)

                const table = tableKeyAliases[tableId] ? tableKeyAliases[tableId] : tableId
                const id = table !== 'products' ? `${table}.${crit}` : crit

                // EnumValues
                let enumValues = critProfile.enum
                    ? critProfile.enum.map(value => ({ text: value, value: value }))
                    : undefined

                if (crit === 'productGroupWebsite') {
                    enumValues = productGroups.map(productGroup => ({
                        text: productGroup.text,
                        value: productGroup.value ? productGroup.value.toString() : 'TODO'
                    }))
                }

                // Text
                let text = i18n.te(`product.${crit}.label`)
                    ? (i18n.t(`product.${crit}.label`) as string)
                    : critProfile.title

                if (tableId === 'technologies') {
                    text = i18n.te(`product.technologies.${crit}`)
                        ? (i18n.t(`product.technologies.${crit}`) as string)
                        : text
                }

                const tableItem: TableCriteriaItem = {
                    categoryId,
                    tableId,
                    criteriaName: crit,
                    id,
                    text,
                    type: determineType(critProfile.type, enumValues),
                    enum: determineEnums(crit, critProfile.type, enumValues),
                    editScope,
                    isInEditScope: determineSegmentEditScope(
                        selectedSegment,
                        isAdministrator,
                        restrictedMarketSegments
                    ),
                    isEditable: determineEditRight(crit, tableId, editScope, isAdministrator, allowedActions)
                }
                if (tableKeyAliases[tableId]) {
                    tableItem.tableAlias = tableKeyAliases[tableId]
                }

                if (categoryId === 'marketing-information' && crit === 'marketingRating') {
                    // TODO: check return
                    if (crit === 'marketingRating') {
                        marketSegments.forEach(seg => {
                            const tabItem: TableCriteriaItem = {
                                categoryId,
                                tableId,
                                criteriaName: crit,
                                id: `${tableId}.${seg.value}`,
                                text: `${seg.text}`,
                                type: determineType(critProfile.type, enumValues),
                                enum: determineEnums(crit, critProfile.type, enumValues),
                                editScope,
                                isInEditScope: determineSegmentEditScope(
                                    selectedSegment,
                                    isAdministrator,
                                    restrictedMarketSegments
                                ),
                                isEditable: determineEditRight(
                                    crit,
                                    tableId,
                                    editScope,
                                    isAdministrator,
                                    allowedActions
                                ),
                                // extra
                                marketingId: seg.value
                            }
                            tableItems.push(tabItem)
                        })
                    }
                    return
                }
                tableItems.push(tableItem)
            })
        }

        const sortedTableItems = tableItems.sort((a, b) => {
            if (a.text > b.text) {
                return 1
            } else if (a.text < b.text) {
                return -1
            } else {
                return 0
            }
        })

        return {
            tableId,
            tableLabel: tableSchema ? tableSchema.title : '',
            tableItems: sortedTableItems
        }
    }
}

const mutations: MutationTree<SchemaState> = {
    setTableItemsByCategory(state, tableItemsByCategory) {
        state.tableItemsByCategory = tableItemsByCategory
    },
    setTableItemsFlat(state, tableItemsFlat) {
        state.tableItemsFlat = [...tableItemsFlat]
    },
    setTableSchemas(state, { tableId, result }) {
        state.tableSchemas[tableId] = Object.assign({}, result)
    },
    setTableSchemasLoaded(state) {
        state.tableSchemasLoaded = true
    },
    setProductGroups(state, productGroupList: SchemaItem[]) {
        state.productGroups = [...productGroupList]
    },
    setRootSegments(state, rootSegments: SchemaItem[]) {
        state.rootSegments = [...rootSegments]
    },
    setSegments(state, segments: SchemaItem[]) {
        state.marketSegments = [...segments]
    },
    setTechnologies(state, technologies: SchemaItem[]) {
        state.technologies = [...technologies]
    }
}

const fetchConfig = { headers: { Accept: 'application/schema+json' } }

const actions: ActionTree<SchemaState, RootState> = {
    async init({ dispatch, commit }, tableIds: string[]) {
        await dispatch('fetchSegments')
        await dispatch('fetchTechnologies')
        await dispatch('fetchProductGroups')
        await Promise.all([...new Set(tableIds)].map(tableId => dispatch('fetchTableSchema', tableId)))
        await dispatch('initTableItems')
        commit('setTableSchemasLoaded')
    },
    async fetchTableSchema({ commit }, tableId: string) {
        const response = await fetchWithAuthCheck(`/api/profile/${tableId}`, fetchConfig)
        const result: TableSchema = await response.json()
        return commit('setTableSchemas', { tableId, result })
    },
    async fetchSegments({ commit }) {
        const responsePromise = await fetchWithAuthCheck('/api/marketSegments?size=1000')
        const response: DuiResponse<'marketSegments', DuiSegmentKey> = await responsePromise.json()
        const rootSegmentList: SchemaItem[] = []
        const segmentList: SchemaItem[] = []

        response._embedded.marketSegments
            .filter(segment => segment.name !== 'global')
            .forEach(segment => {
                const item: SchemaItem = {
                    value: segment.id,
                    valueText: segment.name,
                    childOf: segment.childOf,
                    text: i18n.te(`product.marketSegments.${segment.name}`)
                        ? (i18n.t(`product.marketSegments.${segment.name}`) as string)
                        : segment.name
                }
                if (segment.childOf === 'global') {
                    rootSegmentList.push(item)
                } else {
                    segmentList.push(item)
                }
            })

        segmentList.sort((a, b) => {
            return a.text.localeCompare(b.text)
        })

        commit('setRootSegments', rootSegmentList)
        commit('setSegments', segmentList)
    },
    async fetchTechnologies({ commit }) {
        const responsePromise = await fetchWithAuthCheck('/api/technologyKeys?size=20')
        const response: DuiResponse<'technologyKeys', DuiTechnologyKey> = await responsePromise.json()
        const technologyList: TextValueItem<number>[] = response._embedded.technologyKeys.map(technology => ({
            value: technology.id,
            text: i18n.te(`product.technologies.${technology.name}`)
                ? (i18n.t(`product.technologies.${technology.name}`) as string)
                : technology.name
        }))

        technologyList.sort((a, b) => {
            return a.text.localeCompare(b.text)
        })

        return commit('setTechnologies', technologyList)
    },
    async fetchProductGroups({ commit }) {
        const responsePromise = await fetchWithAuthCheck('/api/productGroups?size=1000')
        const response: DuiResponse<'productGroups', DuiProductGroupEntry> = await responsePromise.json()
        const result: TextValueItem<string>[] = response._embedded.productGroups
            .filter(productGroup => !productGroup.fromFormulationFinder)
            .map(productGroup => ({
                value: productGroup.backendName,
                label: productGroup.officialName,
                text: productGroup.officialName
            }))
            .sort((a, b) => {
                return a.text.localeCompare(b.text)
            })
        commit('setProductGroups', result)
    },
    async initTableItems({ commit, getters }) {
        const tableItemsByCategory: { [key: string]: TableListItem[] | TableCriteriaItem[] } = {}
        let tableItemsFlat: unknown[] = []
        categoryConfig.forEach(category => {
            const categoryItems = category.tableNames?.map((tableName: string) => {
                const tableCriteriaItems: TableListItem = getters.tableItem(tableName, category)
                if (category.excludeColumns) {
                    tableCriteriaItems.tableItems = tableCriteriaItems.tableItems.filter(
                        item => !category.excludeColumns?.includes(item.criteriaName)
                    )
                }

                if (category.tableOptions && category.tableOptions.length > 0) {
                    const currentTableOptions = category.tableOptions.find(options => options.tableName === tableName)

                    if (currentTableOptions && currentTableOptions.strictIncludeColumns) {
                        tableCriteriaItems.tableItems = tableCriteriaItems.tableItems.filter(item =>
                            currentTableOptions.strictIncludeColumns?.includes(item.id)
                        )
                    }
                }
                return tableCriteriaItems
            })

            if (category.hasGroups) {
                tableItemsByCategory[category.id] = categoryItems
            } else {
                tableItemsByCategory[category.id] = categoryItems.map(categoryItem => categoryItem.tableItems).flat()
            }
            tableItemsFlat = tableItemsFlat.concat(categoryItems.map(e => e.tableItems).flat())
        })

        tableItemsFlat = tableItemsFlat.concat(contextBarConfig)

        commit('setTableItemsByCategory', tableItemsByCategory)
        commit('setTableItemsFlat', tableItemsFlat)
    }
}

export default {
    state: schemaState,
    getters: schemaGetters,
    mutations,
    actions
}
